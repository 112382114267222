import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout";
import HeroWithImage from "../../components/heroWithImage/index";
import OurMission from "../../containers/about-us/OurMission";
import OurStory from "../../containers/about-us/OurStory";
import LegacyTimeline from "../../containers/about-us/LegacyTimeline";
import Seo from "../../components/seo";

import heroDesktop from "../../assets/heroDesktop/AboutUs.png";
import heroMobile from "../../assets/heroMobile/AboutUs.png";

const AboutUs = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="ABOUT US" />
      <HeroWithImage
        title={intl.locale === "fr" ? "À PROPOS DE NOUS" : "ABOUT US"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <OurMission data={data.ourmission.nodes[0]} />
      <OurStory data={data.ourstory.nodes[0]} />
      <LegacyTimeline data={data.legacytimeline.nodes[0]} colored={true} />
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery($locale: String) {
    legacytimeline: allContentfulAboutUsOurLegacy(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        timeline {
          items {
            content
            title
            year
          }
        }
        icons {
          file {
            url
          }
        }
        content {
          content
        }
      }
    }
    ourmission: allContentfulAboutUsOurMission(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        content {
          raw
        }
      }
    }
    ourstory: allContentfulAboutUsOurStory(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        content {
          raw
        }
        image {
          file {
            url
          }
        }
        imageLeftBottom {
          file {
            url
          }
        }
        imageRightBottom {
          file {
            url
          }
        }
      }
    }
  }
`;

export default AboutUs;
